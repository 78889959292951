import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50c709a1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "product-section" }
const _hoisted_3 = { class: "product-image" }
const _hoisted_4 = { class: "product-info" }
const _hoisted_5 = { class: "overline" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "reservation-section" }
const _hoisted_8 = { class: "ion-text-wrap" }
const _hoisted_9 = { class: "ion-text-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_DxpShopifyImg = _resolveComponent("DxpShopifyImg")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_back_button, {
                slot: "start",
                "default-href": "/tabs/catalog"
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Product details")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          (Object.keys(_ctx.product).length > 0)
            ? (_openBlock(), _createElementBlock("main", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("section", _hoisted_3, [
                    _createVNode(_component_DxpShopifyImg, {
                      src: _ctx.currentVariant.mainImageUrl
                    }, null, 8, ["src"])
                  ]),
                  _createElementVNode("section", _hoisted_4, [
                    _createVNode(_component_ion_item, { lines: "none" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                          default: _withCtx(() => [
                            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.currentVariant.brandName), 1),
                            _createElementVNode("h1", null, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.currentVariant)), 1),
                            _createElementVNode("h2", null, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.secondaryId, _ctx.currentVariant)), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_note, { slot: "end" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.currentVariant.LIST_PRICE_PURCHASE_USD_STORE_GROUP_price ? _ctx.$n(_ctx.currentVariant.LIST_PRICE_PURCHASE_USD_STORE_GROUP_price, 'currency', _ctx.currency ) : ''), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    (_ctx.selectedColor)
                      ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_list_header, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("Colors")), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_item, { lines: "none" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_row, null, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.features), (colorFeature) => {
                                      return (_openBlock(), _createBlock(_component_ion_chip, {
                                        outline: _ctx.selectedColor !== colorFeature,
                                        key: colorFeature,
                                        onClick: ($event: any) => (_ctx.selectedColor !== colorFeature && _ctx.applyFeature(colorFeature, 'color'))
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(colorFeature), 1)
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]),
                                        _: 2
                                      }, 1032, ["outline", "onClick"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.selectedSize)
                      ? (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_list_header, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("Sizes")), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_item, { lines: "none" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_row, null, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.features[_ctx.selectedColor], (sizeFeature) => {
                                      return (_openBlock(), _createBlock(_component_ion_chip, {
                                        outline: _ctx.selectedSize !== sizeFeature,
                                        key: sizeFeature,
                                        onClick: ($event: any) => (_ctx.selectedSize !== sizeFeature && _ctx.applyFeature(sizeFeature, 'size'))
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(sizeFeature), 1)
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]),
                                        _: 2
                                      }, 1032, ["outline", "onClick"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", null, [
                      _createVNode(_component_ion_segment, { value: _ctx.selectedSegment }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_segment_button, {
                            value: "inStore",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedSegment = 'inStore'))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("In Store")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_segment_button, {
                            value: "otherLocations",
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectedSegment = 'otherLocations'))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("Other Locations")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["value"]),
                      (_ctx.selectedSegment === 'inStore')
                        ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.translate("Quantity on hand")), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_note, { slot: "end" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.getProductStock(_ctx.currentVariant.productId).quantityOnHandTotal ?? '0'), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.translate("Safety stock")), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_note, { slot: "end" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.getInventoryInformation(_ctx.currentVariant.productId).minimumStock ?? '0'), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.translate("Order reservations")), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_note, { slot: "end" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.getInventoryInformation(_ctx.currentVariant.productId).reservedQuantity ?? '0'), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_item, { lines: "none" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.translate("Available to promise")), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_badge, {
                                    color: "success",
                                    slot: "end"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.getInventoryInformation(_ctx.currentVariant.productId).onlineAtp ?? '0'), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.selectedSegment === 'otherLocations')
                        ? (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.translate("Other stores")), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_button, {
                                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.getOtherStoresInventoryDetails())),
                                    fill: "outline"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.translate('ATP', { count: _ctx.otherStoresInventory})), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_item, { lines: "none" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.translate("Warehouse")), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_note, { slot: "end" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.translate('ATP', { count: _ctx.warehouseInventory})), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]),
                (_ctx.orders.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("h3", null, _toDisplayString(_ctx.translate('order reservations at', { count: _ctx.getInventoryInformation(_ctx.currentVariant.productId).reservedQuantity ?? '0', store: _ctx.currentFacility.facilityName })), 1),
                      _createElementVNode("div", _hoisted_7, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orders, (order, index) => {
                          return (_openBlock(), _createBlock(_component_ion_card, { key: index }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, { lines: "none" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("h1", null, _toDisplayString(order.customer.name), 1),
                                      _createElementVNode("p", null, _toDisplayString(order.orderName ? order.orderName : order.orderId), 1)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_ion_badge, {
                                    color: "primary",
                                    slot: "end"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(order.shipmentMethod.shipmentMethodTypeDesc ? order.shipmentMethod.shipmentMethodTypeDesc : order.shipmentMethod.shipmentMethodTypeId), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_ion_item, { lines: "none" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_DxpShopifyImg, {
                                        src: _ctx.getProduct(order.currentItem.productId).mainImageUrl,
                                        size: "small"
                                      }, null, 8, ["src"])
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("h2", null, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(order.currentItem.productId)) ? _ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(order.currentItem.productId)) : order.currentItem.productId), 1),
                                      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.secondaryId, _ctx.getProduct(order.currentItem.productId))), 1)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_ion_note, { slot: "end" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.translate(order.currentItem.quantity == 1 ? "unit" : "units", { item: order.currentItem.quantity })), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024),
                              (order.otherItems.length > 0)
                                ? (_openBlock(), _createBlock(_component_ion_list_header, {
                                    key: 0,
                                    color: "light"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_label, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.translate("Other items")), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(order.otherItems, (item, index) => {
                                return (_openBlock(), _createBlock(_component_ion_item, {
                                  lines: "none",
                                  key: index
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_DxpShopifyImg, {
                                          src: _ctx.getProduct(item.productId).mainImageUrl,
                                          size: "small"
                                        }, null, 8, ["src"])
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("h2", null, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) ? _ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) : item.productId), 1),
                                        _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.secondaryId, _ctx.getProduct(item.productId))), 1)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_ion_note, { slot: "end" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.translate(item.quantity == 1 ? "unit" : "units", { item: item.quantity })), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}