import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withKeys as _withKeys, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b931c2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "metadata" }
const _hoisted_3 = { class: "border-top" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "metadata" }
const _hoisted_6 = { class: "border-top" }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { class: "metadata" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ProductListItem = _resolveComponent("ProductListItem")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_back_button, {
                "default-href": "/",
                slot: "start"
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Shipping to store")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createElementVNode("div", null, [
            _createVNode(_component_ion_searchbar, {
              onIonFocus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectSearchBarText($event))),
              modelValue: _ctx.queryString,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.queryString) = $event)),
              onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => {_ctx.queryString = $event.target.value; _ctx.searchOrders()}, ["enter"])),
              placeholder: _ctx.translate('Search')
            }, null, 8, ["modelValue", "placeholder"]),
            _createVNode(_component_ion_segment, {
              modelValue: _ctx.segmentSelected,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.segmentSelected) = $event)),
              onIonChange: _ctx.segmentChanged
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_segment_button, { value: "incoming" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("Incoming")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_segment_button, { value: "readyForPickup" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("Ready for pickup")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_segment_button, { value: "completed" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("Completed")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue", "onIonChange"])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, {
        ref: "contentRef",
        "scroll-events": true,
        onIonScroll: _cache[6] || (_cache[6] = ($event: any) => (_ctx.enableScrolling()))
      }, {
        default: _withCtx(() => [
          (_ctx.segmentSelected === 'incoming')
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.incomingOrders, (order, index) => {
                  return _withDirectives((_openBlock(), _createElementBlock("div", { key: index }, [
                    _createVNode(_component_ion_card, { button: "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                              default: _withCtx(() => [
                                _createElementVNode("h1", null, _toDisplayString(order.firstName) + " " + _toDisplayString(order.lastName), 1),
                                _createElementVNode("p", null, _toDisplayString(order.orderName ? order.orderName : order.orderId), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createElementVNode("div", _hoisted_2, [
                              (order.createdDate)
                                ? (_openBlock(), _createBlock(_component_ion_note, { key: 0 }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.getDateTime(order.createdDate)), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                : _createCommentVNode("", true)
                            ])
                          ]),
                          _: 2
                        }, 1024),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(order.items, (item) => {
                          return (_openBlock(), _createBlock(_component_ProductListItem, {
                            key: item.productId,
                            item: item,
                            isShipToStoreOrder: true
                          }, null, 8, ["item"]))
                        }), 128)),
                        _createElementVNode("div", _hoisted_3, [
                          _createVNode(_component_ion_button, {
                            disabled: !_ctx.hasPermission(_ctx.Actions.APP_ORDER_UPDATE),
                            fill: "clear",
                            onClick: _withModifiers(($event: any) => (_ctx.confirmScheduleOrderForPickup(order)), ["stop"])
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("Arrived")), 1)
                            ]),
                            _: 2
                          }, 1032, ["disabled", "onClick"])
                        ])
                      ]),
                      _: 2
                    }, 1024)
                  ])), [
                    [_vShow, order.items.length]
                  ])
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.segmentSelected === 'readyForPickup')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.readyForPickupOrders, (order, index) => {
                  return _withDirectives((_openBlock(), _createElementBlock("div", { key: index }, [
                    _createVNode(_component_ion_card, { button: "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                              default: _withCtx(() => [
                                _createElementVNode("h1", null, _toDisplayString(order.firstName) + " " + _toDisplayString(order.lastName), 1),
                                _createElementVNode("p", null, _toDisplayString(order.orderName ? order.orderName : order.orderId), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createElementVNode("div", _hoisted_5, [
                              (order.createdDate)
                                ? (_openBlock(), _createBlock(_component_ion_note, { key: 0 }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.getDateTime(order.createdDate)), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                : _createCommentVNode("", true)
                            ])
                          ]),
                          _: 2
                        }, 1024),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(order.items, (item) => {
                          return (_openBlock(), _createBlock(_component_ProductListItem, {
                            key: item.productId,
                            item: item,
                            isShipToStoreOrder: true
                          }, null, 8, ["item"]))
                        }), 128)),
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_ion_button, {
                            disabled: !_ctx.hasPermission(_ctx.Actions.APP_ORDER_UPDATE),
                            fill: "clear",
                            onClick: _withModifiers(($event: any) => (_ctx.confirmHandoverOrder(order.shipmentId)), ["stop"])
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("Handover")), 1)
                            ]),
                            _: 2
                          }, 1032, ["disabled", "onClick"]),
                          _createVNode(_component_ion_button, {
                            fill: "clear",
                            slot: "end",
                            onClick: ($event: any) => (_ctx.sendReadyForPickupEmail(order))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                slot: "icon-only",
                                icon: _ctx.mailOutline
                              }, null, 8, ["icon"])
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ])
                      ]),
                      _: 2
                    }, 1024)
                  ])), [
                    [_vShow, order.items.length]
                  ])
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.segmentSelected === 'completed')
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.completedOrders, (order, index) => {
                  return _withDirectives((_openBlock(), _createElementBlock("div", { key: index }, [
                    _createVNode(_component_ion_card, { button: "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                              default: _withCtx(() => [
                                _createElementVNode("h1", null, _toDisplayString(order.firstName) + " " + _toDisplayString(order.lastName), 1),
                                _createElementVNode("p", null, _toDisplayString(order.orderName ? order.orderName : order.orderId), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createElementVNode("div", _hoisted_8, [
                              (order.createdDate)
                                ? (_openBlock(), _createBlock(_component_ion_note, { key: 0 }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.getDateTime(order.createdDate)), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                : _createCommentVNode("", true)
                            ])
                          ]),
                          _: 2
                        }, 1024),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(order.items, (item) => {
                          return (_openBlock(), _createBlock(_component_ProductListItem, {
                            key: item.productId,
                            item: item,
                            isShipToStoreOrder: true
                          }, null, 8, ["item"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1024)
                  ])), [
                    [_vShow, order.items.length]
                  ])
                }), 128))
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_refresher, {
            slot: "fixed",
            onIonRefresh: _cache[4] || (_cache[4] = ($event: any) => (_ctx.refreshOrders($event)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_refresher_content, {
                pullingIcon: "crescent",
                refreshingSpinner: "crescent"
              })
            ]),
            _: 1
          }),
          _withDirectives(_createVNode(_component_ion_infinite_scroll, {
            onIonInfinite: _cache[5] || (_cache[5] = ($event: any) => (_ctx.loadMoreOrders($event))),
            threshold: "100px",
            ref: "infiniteScrollRef"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_infinite_scroll_content, {
                "loading-spinner": "crescent",
                "loading-text": _ctx.translate('Loading')
              }, null, 8, ["loading-text"])
            ]),
            _: 1
          }, 512), [
            [_vShow, (_ctx.segmentSelected === 'incoming' ? _ctx.isIncomingOrdersScrollable : _ctx.segmentSelected === 'readyForPickup' ? _ctx.isReadyForPickupOrdersScrollable : _ctx.isCompletedOrdersScrollable)]
          ])
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }))
}