import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-37335fa3"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "empty-state"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "overline" }
const _hoisted_4 = {
  key: 7,
  class: "ion-margin-top ion-hide-md-down"
}
const _hoisted_5 = {
  key: 8,
  class: "ion-margin-top ion-hide-md-down"
}
const _hoisted_6 = {
  key: 0,
  class: "border-top"
}
const _hoisted_7 = {
  key: 0,
  class: "empty-state"
}
const _hoisted_8 = {
  class: "ion-padding",
  slot: "content"
}
const _hoisted_9 = { class: "overline" }
const _hoisted_10 = { class: "ion-text-wrap" }
const _hoisted_11 = { slot: "end" }
const _hoisted_12 = {
  key: 1,
  class: "atp-info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ProductListItem = _resolveComponent("ProductListItem")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_DxpShopifyImg = _resolveComponent("DxpShopifyImg")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_accordion = _resolveComponent("ion-accordion")!
  const _component_ion_accordion_group = _resolveComponent("ion-accordion-group")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_back_button, {
                "default-href": "/",
                slot: "start"
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Order details")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  (_ctx.orderType === 'packed' && _ctx.order.part?.shipmentMethodEnum?.shipmentMethodEnumId === 'STOREPICKUP')
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 0,
                        class: "ion-hide-md-up",
                        disabled: !_ctx.order?.orderId || !_ctx.hasPermission(_ctx.Actions.APP_ORDER_UPDATE) || _ctx.order.handovered || _ctx.order.shipped,
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.sendReadyForPickupEmail(_ctx.order)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            slot: "icon-only",
                            icon: _ctx.mailOutline
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }, 8, ["disabled"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_button, {
                    disabled: !_ctx.order?.orderId,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openOrderItemRejHistoryModal()))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "icon-only",
                        icon: _ctx.timeOutline
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["disabled"]),
                  (_ctx.orderType === 'open')
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 1,
                        class: "ion-hide-md-up",
                        disabled: !_ctx.order?.orderId || !_ctx.hasPermission(_ctx.Actions.APP_ORDER_UPDATE) || _ctx.order.readyToHandover || _ctx.order.readyToShip || _ctx.order.rejected,
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.rejectOrder()))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            slot: "icon-only",
                            color: "danger",
                            icon: _ctx.bagRemoveOutline
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }, 8, ["disabled"]))
                    : (_ctx.orderType === 'packed' && _ctx.getBopisProductStoreSettings('PRINT_PACKING_SLIPS'))
                      ? (_openBlock(), _createBlock(_component_ion_button, {
                          key: 2,
                          class: _normalizeClass(_ctx.order.part?.shipmentMethodEnum?.shipmentMethodEnumId !== 'STOREPICKUP' ? 'ion-hide-md-up' : ''),
                          disabled: !_ctx.order?.orderId || !_ctx.hasPermission(_ctx.Actions.APP_ORDER_UPDATE) || _ctx.order.handovered || _ctx.order.shipped,
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.order.part?.shipmentMethodEnum?.shipmentMethodEnumId === 'STOREPICKUP' ? _ctx.printPackingSlip(_ctx.order) : _ctx.printShippingLabelAndPackingSlip(_ctx.order)))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, {
                              slot: "icon-only",
                              icon: _ctx.printOutline
                            }, null, 8, ["icon"])
                          ]),
                          _: 1
                        }, 8, ["class", "disabled"]))
                      : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          (!_ctx.order?.orderId)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("p", null, _toDisplayString(_ctx.translate("Order not found")), 1)
              ]))
            : (_openBlock(), _createElementBlock("main", _hoisted_2, [
                _createElementVNode("aside", null, [
                  (_ctx.order.readyToHandover || _ctx.order.readyToShip)
                    ? (_openBlock(), _createBlock(_component_ion_item, {
                        key: 0,
                        color: "light",
                        lines: "none"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            icon: _ctx.checkmarkCircleOutline,
                            color: "success",
                            slot: "start"
                          }, null, 8, ["icon"]),
                          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.order.readyToHandover ? _ctx.translate("Order is now ready to handover.") : _ctx.translate("Order is now ready to be shipped.")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : (_ctx.order.rejected)
                      ? (_openBlock(), _createBlock(_component_ion_item, {
                          key: 1,
                          color: "light",
                          lines: "none"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, {
                              icon: _ctx.closeCircleOutline,
                              color: "danger",
                              slot: "start"
                            }, null, 8, ["icon"]),
                            _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("Order has been rejected.")), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                  (_ctx.order.handovered || _ctx.order.shipped)
                    ? (_openBlock(), _createBlock(_component_ion_item, {
                        key: 2,
                        color: "light",
                        lines: "none"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            icon: _ctx.checkmarkCircleOutline,
                            color: "success",
                            slot: "start"
                          }, null, 8, ["icon"]),
                          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.order.handovered ? _ctx.translate("Order is successfully handed over to customer.") : _ctx.translate("Order is successfully shipped.")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_row, { class: "order-header ion-justify-content-between ion-wrap ion-align-items-center" }, {
                    default: _withCtx(() => [
                      _createElementVNode("h1", null, _toDisplayString(_ctx.order?.orderName ? _ctx.order?.orderName : _ctx.order?.orderId), 1),
                      (_ctx.order?.orderPaymentPreferences)
                        ? (_openBlock(), _createBlock(_component_ion_chip, {
                            key: 0,
                            outline: "",
                            color: _ctx.statusColor[_ctx.order?.orderPaymentPreferences[0]?.statusId]
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, { icon: _ctx.cashOutline }, null, 8, ["icon"]),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.getPaymentMethodDesc(_ctx.order?.orderPaymentPreferences[0]?.paymentMethodTypeId)) + " : " + _toDisplayString(_ctx.getStatusDesc(_ctx.order?.orderPaymentPreferences[0]?.statusId)), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["color"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_list, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, { lines: "none" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.order?.customer?.name), 1)
                            ]),
                            _: 1
                          }),
                          (_ctx.order?.placedDate)
                            ? (_openBlock(), _createBlock(_component_ion_badge, {
                                key: 0,
                                slot: "end"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.timeFromNow(_ctx.order.placedDate)), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (_ctx.customerEmail)
                    ? (_openBlock(), _createBlock(_component_ion_item, {
                        key: 3,
                        lines: "none"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            icon: _ctx.mailOutline,
                            slot: "start"
                          }, null, 8, ["icon"]),
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.customerEmail), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_button, {
                            slot: "end",
                            fill: "clear",
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.copyToClipboard(_ctx.customerEmail, false)))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                color: "medium",
                                icon: _ctx.copyOutline
                              }, null, 8, ["icon"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.order?.contactNumber)
                    ? (_openBlock(), _createBlock(_component_ion_item, {
                        key: 4,
                        lines: "none"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            icon: _ctx.callOutline,
                            slot: "start"
                          }, null, 8, ["icon"]),
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.order?.contactNumber), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_button, {
                            slot: "end",
                            fill: "clear",
                            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.copyToClipboard(_ctx.order?.contactNumber, false)))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                color: "medium",
                                icon: _ctx.copyOutline
                              }, null, 8, ["icon"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.order?.shippingInstructions)
                    ? (_openBlock(), _createBlock(_component_ion_item, {
                        key: 5,
                        color: "light",
                        lines: "none"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                            default: _withCtx(() => [
                              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.translate("Handling Instructions")), 1),
                              _createElementVNode("p", null, _toDisplayString(_ctx.order.shippingInstructions), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.orderType === 'packed' && _ctx.getBopisProductStoreSettings('ENABLE_TRACKING') && _ctx.order.pickers)
                    ? (_openBlock(), _createBlock(_component_ion_item, {
                        key: 6,
                        lines: "none"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.order.pickers ? _ctx.translate("Picked by", { pickers: _ctx.order.pickers }) : _ctx.translate("No picker assigned.")), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_button, {
                            disabled: !_ctx.hasPermission(_ctx.Actions.APP_ORDER_UPDATE),
                            fill: "outline",
                            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.editPicker(_ctx.order)))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("Edit")), 1)
                            ]),
                            _: 1
                          }, 8, ["disabled"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.orderType === 'open')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createVNode(_component_ion_button, {
                          disabled: !_ctx.hasPermission(_ctx.Actions.APP_ORDER_UPDATE) || _ctx.order.readyToHandover || _ctx.order.readyToShip || _ctx.order.rejected,
                          expand: "block",
                          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.readyForPickup(_ctx.order, _ctx.order.part)))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.order?.part?.shipmentMethodEnum?.shipmentMethodEnumId === 'STOREPICKUP' ? _ctx.translate("Ready for pickup") : _ctx.translate("Ready to ship")), 1)
                          ]),
                          _: 1
                        }, 8, ["disabled"]),
                        _createVNode(_component_ion_button, {
                          class: "ion-margin-top",
                          disabled: !_ctx.hasPermission(_ctx.Actions.APP_ORDER_UPDATE) || _ctx.order.readyToHandover || _ctx.order.readyToShip || _ctx.order.rejected,
                          expand: "block",
                          color: "danger",
                          fill: "outline",
                          onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.rejectOrder()))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate("Reject Order")), 1)
                          ]),
                          _: 1
                        }, 8, ["disabled"]),
                        _createElementVNode("p", null, _toDisplayString(_ctx.translate("If you cannot fulfill this order, will be sent an email with alternate fulfillment options and this order will be removed from your dashboard.", { customerName: _ctx.order?.customer?.name })), 1)
                      ]))
                    : (_ctx.orderType === 'packed')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _createVNode(_component_ion_button, {
                            disabled: !_ctx.hasPermission(_ctx.Actions.APP_ORDER_UPDATE) || _ctx.order.handovered || _ctx.order.shipped,
                            expand: "block",
                            fill: "outline",
                            onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.order?.part?.shipmentMethodEnum?.shipmentMethodEnumId === 'STOREPICKUP' ? _ctx.sendReadyForPickupEmail(_ctx.order) : _ctx.printShippingLabelAndPackingSlip(_ctx.order)))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.order?.part?.shipmentMethodEnum?.shipmentMethodEnumId === 'STOREPICKUP' ? _ctx.translate("Resend customer email") : _ctx.translate("Generate shipping documents")), 1)
                            ]),
                            _: 1
                          }, 8, ["disabled"]),
                          _createVNode(_component_ion_button, {
                            class: "ion-margin-top",
                            disabled: !_ctx.hasPermission(_ctx.Actions.APP_ORDER_UPDATE) || _ctx.order.handovered || _ctx.order.shipped,
                            expand: "block",
                            onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.deliverShipment(_ctx.order)))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.order.part?.shipmentMethodEnum?.shipmentMethodEnumId === 'STOREPICKUP' ? _ctx.translate("Handover") : _ctx.translate("Ship")), 1)
                            ]),
                            _: 1
                          }, 8, ["disabled"])
                        ]))
                      : _createCommentVNode("", true)
                ]),
                _createElementVNode("section", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.order.part?.items, (item, index) => {
                    return (_openBlock(), _createBlock(_component_ion_card, { key: index }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ProductListItem, { item: item }, null, 8, ["item"]),
                        (_ctx.orderType === 'open' && _ctx.partialOrderRejectionConfig?.settingValue == 'true')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                              _createVNode(_component_ion_button, {
                                disabled: _ctx.order?.readyToHandover || _ctx.order.readyToShip || _ctx.order?.rejected,
                                fill: "clear",
                                onClick: ($event: any) => (_ctx.openReportAnIssueModal(item))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.translate("Report an issue")), 1)
                                ]),
                                _: 2
                              }, 1032, ["disabled", "onClick"])
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128)),
                  (!_ctx.order.part?.items?.length)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.translate('All order items are rejected')), 1))
                    : _createCommentVNode("", true),
                  (_ctx.order.shipGroups?.length)
                    ? (_openBlock(), _createBlock(_component_ion_accordion_group, { key: 1 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_accordion, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, { slot: "header" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.translate("Other shipments in this order")), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createElementVNode("div", _hoisted_8, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.order.shipGroups, (shipGroup) => {
                                  return (_openBlock(), _createBlock(_component_ion_card, {
                                    key: shipGroup.shipmentId
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_card_header, null, {
                                        default: _withCtx(() => [
                                          _createElementVNode("div", null, [
                                            _createVNode(_component_ion_card_subtitle, { class: "overline" }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.getfacilityTypeDesc(shipGroup.facilityTypeId)), 1)
                                              ]),
                                              _: 2
                                            }, 1024),
                                            _createVNode(_component_ion_card_title, null, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(shipGroup.facilityName), 1)
                                              ]),
                                              _: 2
                                            }, 1024),
                                            _createTextVNode(" " + _toDisplayString(shipGroup.shipGroupSeqId), 1)
                                          ]),
                                          _createVNode(_component_ion_badge, {
                                            color: shipGroup.category ? 'primary' : 'medium'
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(shipGroup.category ? shipGroup.category : _ctx.translate('Pending allocation')), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["color"])
                                        ]),
                                        _: 2
                                      }, 1024),
                                      (shipGroup.carrierPartyId)
                                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.getPartyName(shipGroup.carrierPartyId)) + " ", 1),
                                              _createVNode(_component_ion_label, { slot: "end" }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(shipGroup.trackingCode), 1)
                                                ]),
                                                _: 2
                                              }, 1024),
                                              _createVNode(_component_ion_icon, {
                                                slot: "end",
                                                icon: _ctx.locateOutline
                                              }, null, 8, ["icon"])
                                            ]),
                                            _: 2
                                          }, 1024))
                                        : _createCommentVNode("", true),
                                      (shipGroup.shippingInstructions)
                                        ? (_openBlock(), _createBlock(_component_ion_item, {
                                            key: 1,
                                            color: "light",
                                            lines: "none"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.translate("Handling Instructions")), 1),
                                                  _createElementVNode("p", null, _toDisplayString(shipGroup.shippingInstructions), 1)
                                                ]),
                                                _: 2
                                              }, 1024)
                                            ]),
                                            _: 2
                                          }, 1024))
                                        : _createCommentVNode("", true),
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(shipGroup.items, (item) => {
                                        return (_openBlock(), _createBlock(_component_ion_item, {
                                          lines: "none",
                                          key: item
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_DxpShopifyImg, {
                                                  src: _ctx.getProduct(item.productId).mainImageUrl,
                                                  size: "small"
                                                }, null, 8, ["src"])
                                              ]),
                                              _: 2
                                            }, 1024),
                                            _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("h2", null, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) ? _ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) : _ctx.getProduct(item.productId).productName), 1),
                                                _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.secondaryId, _ctx.getProduct(item.productId))), 1)
                                              ]),
                                              _: 2
                                            }, 1024),
                                            _createElementVNode("div", _hoisted_11, [
                                              (item.isFetchingStock)
                                                ? (_openBlock(), _createBlock(_component_ion_spinner, {
                                                    key: 0,
                                                    color: "medium",
                                                    name: "crescent"
                                                  }))
                                                : (_ctx.getProductStock(item.productId).quantityOnHandTotal >= 0)
                                                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                                      _createVNode(_component_ion_note, { slot: "end" }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString(_ctx.translate("on hand", { count: _ctx.getProductStock(item.productId).quantityOnHandTotal ?? '0' })), 1)
                                                        ]),
                                                        _: 2
                                                      }, 1024),
                                                      _createVNode(_component_ion_button, {
                                                        fill: "clear",
                                                        onClick: _withModifiers(($event: any) => (_ctx.openInventoryDetailPopover($event, item)), ["stop"])
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_ion_icon, {
                                                            slot: "icon-only",
                                                            icon: _ctx.informationCircleOutline,
                                                            color: "medium"
                                                          }, null, 8, ["icon"])
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["onClick"])
                                                    ]))
                                                  : (_openBlock(), _createBlock(_component_ion_button, {
                                                      key: 2,
                                                      fill: "clear",
                                                      onClick: _withModifiers(($event: any) => (_ctx.fetchProductStock(item.productId, shipGroup.shipGroupSeqId)), ["stop"])
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_ion_icon, {
                                                          color: "medium",
                                                          slot: "icon-only",
                                                          icon: _ctx.cubeOutline
                                                        }, null, 8, ["icon"])
                                                      ]),
                                                      _: 2
                                                    }, 1032, ["onClick"]))
                                            ])
                                          ]),
                                          _: 2
                                        }, 1024))
                                      }), 128))
                                    ]),
                                    _: 2
                                  }, 1024))
                                }), 128))
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ])
              ])),
          (_ctx.orderType === 'open' && _ctx.order?.orderId)
            ? (_openBlock(), _createBlock(_component_ion_fab, {
                key: 2,
                class: "ion-hide-md-up",
                vertical: "bottom",
                horizontal: "end",
                slot: "fixed"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_fab_button, {
                    disabled: !_ctx.hasPermission(_ctx.Actions.APP_ORDER_UPDATE) || _ctx.order.readyToHandover || _ctx.order.readyToShip || _ctx.order.rejected,
                    onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.readyForPickup(_ctx.order, _ctx.order.part)))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.order.part?.shipmentMethodEnum?.shipmentMethodEnumId === 'STOREPICKUP' ? _ctx.bagHandleOutline : _ctx.giftOutline
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ]),
                _: 1
              }))
            : (_ctx.orderType === 'packed' && _ctx.order?.orderId)
              ? (_openBlock(), _createBlock(_component_ion_fab, {
                  key: 3,
                  class: "ion-hide-md-up",
                  vertical: "bottom",
                  horizontal: "end",
                  slot: "fixed"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_fab_button, {
                      disabled: !_ctx.hasPermission(_ctx.Actions.APP_ORDER_UPDATE) || _ctx.order.handovered || _ctx.order.shipped,
                      onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.deliverShipment(_ctx.order)))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          icon: _ctx.order.part?.shipmentMethodEnum?.shipmentMethodEnumId === 'STOREPICKUP' ? _ctx.accessibilityOutline : _ctx.checkmarkOutline
                        }, null, 8, ["icon"])
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}